<template>
  <div :class="layoutPcMi ? 'user' : 'user-ml'">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item>会员中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <h3>我的账户</h3>
      <el-divider></el-divider>
      <div class="items-content">
        <div v-for="(item, i) of myUser" :key="i" class="item-content" @click="routerClick(item)">
          <i :class="item.icon"></i>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <h3>我的订单</h3>
      <el-divider></el-divider>
      <div class="items-content">
        <div v-for="(item, i) of myOrder" :key="i" class="item-content" @click="routerClick(item)">
          <i :class="item.icon"></i>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <h3>联盟账号</h3>
      <el-divider></el-divider>
      <div class="items-content">
        <div v-for="(item, i) of unionUser" :key="i" class="item-content" @click="routerClick(item)">
          <i :class="item.icon"></i>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <h3>我的订阅</h3>
      <el-divider></el-divider>
      <div class="items-content">
        <div v-for="(item, i) of mySubscribe" :key="i" class="item-content" @click="routerClick(item)">
          <i :class="item.icon"></i>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user',
  data () {
    return {
      layoutPcMi: this.$store.state.layoutPcMi,
      myUser: [
        {
          text: '编辑账号',
          path: '/userInfo',
          icon: 'el-icon-add-location'
        }, {
          text: '修改密码',
          path: '/updatePass',
          icon: 'el-icon-add-location'
        }, {
          text: '地址管理',
          path: '/addressMana',
          icon: 'el-icon-add-location'
        }, {
          text: '收藏商品',
          path: '/myCollection',
          icon: 'el-icon-add-location'
        }
      ],
      myOrder: [
        {
          text: '历史订单',
          path: '/order',
          icon: 'el-icon-add-location'
        }, {
          text: '商品下载',
          path: '/fileDownload',
          icon: 'el-icon-add-location'
        }, {
          text: '我的积分',
          path: '/myIntegral',
          icon: 'el-icon-add-location'
        }, {
          text: '商品退还',
          path: '/goodsReturn',
          icon: 'el-icon-add-location'
        }, {
          text: '我的余额',
          path: '/balance',
          icon: 'el-icon-add-location'
        }
      ],
      unionUser: [
        {
          text: '注册推广联盟账号',
          path: '/registeredUnion',
          icon: 'el-icon-add-location'
        }
      ],
      mySubscribe: [
        {
          text: '邮件订阅',
          path: '/mailSubscribe',
          icon: 'el-icon-add-location'
        }
      ]
    }
  },
  methods: {
    routerClick (item) {
      this.$router.push(`${item.path}`)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.user {
  @include body;
  .content {
    width: 100%;
    .items-content {
      display: flex;
      .item-content {
        width: 10%;
        border: 1px solid #D2D2D2;
        border-radius: 10px;
        padding-top: 10px;
        text-align: center;
        margin-right: 20px;
        font-size: 14px;
        i {
          font-size: 30px;
        }
        &:hover {
          cursor: pointer;
          background-color: $theme-color;
          box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, .1);
          transition: background-color linear 500ms;
          background: rgb(255, 218, 0);
        }
      }
    }
  }
}
.user-ml {
  width: 100%;
  @extend .user;
  .el-divider {
    margin-bottom: 0 !important;
  }
  .items-content {
    flex-wrap: wrap;
    padding-left: 20px;
    .item-content {
      margin-top: 20px;
      width: 25% !important;
    }
  }
}
</style>
